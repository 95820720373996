import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const MorskiPage = () => (
  <Layout>
    <SEO title="Weekend na końcu Polski" />
    <section className="single-trip-description">
      <div className="wrap">
        <div className="negative-margin-left negative-margin-photo">
          <img src={`/static/weekend-na-koncu-polski.jpg`} alt="" />
        </div>
        <div>
          <h3>ZAREZERWUJ WEEKEND PEŁEN MORSKICH PRZYGÓD</h3>
          <h1>Weekend na końcu Polski!</h1>
          <p>
            Z przyjemnością przedstawiamy ofertę pełną aktywności na morzu. To
            idealny pomysł na weekend dla wszystkich lubiących kontakt z wodą i
            aktywny wypoczynek. Rejs statkiem po Zatoce Gdańskiej to
            fantastyczna okazja do przeżycia małej żeglarskiej przygody. Po
            dotarciu na Hel czeka Was wyśmienita uczta w lokalnej restauracji w
            iście żeglarskim stylu. Całodzienny rejs na pewno zaspokoi Wasze
            pragnienia na morską przygodę. Następnego dnia, nadmorski kurort
            czyli Sopot, przywita Was z otwartymi ramionami. Piaszczyste plaże,
            różnorodność restauracji, kawiarnie i piękna marina sprawiają, że
            będziecie chcieć pozostać w naszych rejonach na dłużej. Oczywiście
            nie chcielibyśmy Was wypuszczać z Trójmiasta bez zobaczenia zachodu
            słońca z pokładu jachtu.
          </p>
        </div>
        <div className="trip-plan">
          <h4>W programie proponujemy:</h4>
          <div className="day">
            <div className="day-number">Dzień 1</div>
            <div className="day-plan">
              <ul>
                <li>Transfer z lotniska / dworca PKP</li>
                <li>Zakwaterowanie w hotelu / pensjonacie / apartamencie</li>
                <li>Spacer Drogą Królewską z przewodnikiem</li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 2</div>
            <div className="day-plan">
              <ul>
                <li>Rejs statkiem na Hel</li>
                <li>Zwiedzanie fokarium, latarni morskiej i spacer po plaży</li>
                <li>Obiad w restauracji z menu z rybami bałtyckimi</li>
                <li>Powrót statkiem do Gdańska</li>
                <li>
                  Wieczorem degustacja piwa w browarni przy przystani jachtowej
                </li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 3</div>
            <div className="day-plan">
              <ul>
                <li>
                  Zwiedzanie Sopotu – deptak Mont Cassino, molo i spacer wzdłuż
                  plaży
                </li>
                <li>Obiad w restauracji z widokiem na Zatokę Gdańską</li>
                <li>Transfer na lotnisko / dworzec PKP</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <ContactForm />
        </div>
        <div className=" prices">
          <div>
            CENA OD: 450 zł / za osobę
            <p> CENA ZAWIERA</p>
            <ul>
              <li>Transfer </li>
              <li>Zakwaterowanie </li>
              <li>Towarzystwo przewodnika turystycznego </li>
            </ul>
          </div>
          <div>
            Cena zależy od ilości osób, programu i standardu noclegu. Cenę
            kalkulujemy dla każdego klienta indywidualnie, ponieważ zależy ona
            od ilości osób, programu i rodzaju noclegu. Każdy program
            dostosowany jest do życzeń naszych gości oraz do ich harmonogramu
            podróży.
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default MorskiPage
